<template>
  <div class="audit-logs">
    <!-- 面包屑 -->
    <els-bread class="_bread"></els-bread>
    <div class="_header com-top">
      <div>
        <el-date-picker
          v-model="searchDate"
          type="datetimerange"
          :range-separator="lang.to"
          value-format="timestamp"
          :start-placeholder="lang.startDate"
          :end-placeholder="lang.endDate"
          @change="searchData"
        >
        </el-date-picker>
        <!--      <el-select v-model="searchType" placeholder="请选择实体类型" style="margin:0 16px;">-->
        <!--        <el-option-->
        <!--                v-for="item in entityType"-->
        <!--                :key="item"-->
        <!--                :label="item"-->
        <!--                :value="item">-->
        <!--        </el-option>-->
        <!--      </el-select>-->
      </div>
      <div>
        <!--        <el-input-->
        <!--                v-model="searchName"-->
        <!--                placeholder="实体名称" style="width:280px"-->
        <!--                suffix-icon="el-icon-search"-->
        <!--                @change="searchData"-->
        <!--        ></el-input>-->
      </div>
    </div>
    <div class="_content com-bottom">
      <els-table
        ref="listRef"
        :data="tableData"
        :column="column"
        :table="tableOption"
        :showMenu="false"
        :loading="isLoading"
      >
        <template v-slot:entityType="{ row }">
          <span>{{ getRowEntityType(row) }}</span>
        </template>
      </els-table>
    </div>
  </div>
</template>
<script>
import elsTable from "@/components/els-table/main.vue";
import { getAuditLogsList, queryEntityTypes } from "@/api/logs/audit-logs.js";
import elsBread from "@/components/els-breadcrumb/main.vue";
import { mapState } from "vuex";
import mixinsI18n from "@/mixins/i18n.js";
export default {
  name: "audit-logs",
  mixins: [mixinsI18n],
  components: { elsTable, elsBread },
  data() {
    return {
      PAGE_NAME: "auditLogPage",
      searchDate: [],
      searchName: "",
      entityType: [],
      searchType: "",
      query: {},
      tableData: [],
      isLoading: false,
    };
  },
  mounted() {
    const myDate = new Date();
    // const startDate= (new Date(`${myDate.getFullYear()}-${myDate.getMonth()+1}-${myDate.getDate()} 00:00:00`)).getTime();

    // const endDate= (new Date(`${myDate.getFullYear()}-${myDate.getMonth()+1}-${myDate.getDate()} 23:59:59`)).getTime();
    const endDate = new Date().getTime();
    const startDate = new Date(endDate - 3600 * 1000).getTime();
    this.searchDate.push(startDate);
    this.searchDate.push(endDate);
    this.getEntityType();
    this.getTableData();
  },
  computed: {
    ...mapState(["breadMenus"]),
    column() {
      const col1 = {
        label: this.lang.timeStamp,
        prop: "createdTime",
        valueType: "date",
      };
      const col2 = {
        label: this.lang?.entityType,
        prop: "entityType",
        hasSlot: true,
      };
      const col3 = { label: this.lang.entityName, prop: "entityName" };
      const col4 = { label: this.lang.user, prop: "userName" };
      const col5 = { label: this.lang.type, prop: "actionType" };
      const col6 = { label: this.lang.state, prop: "actionStatus" };
      const col7 = { label: this.lang.details, prop: "actionData" };
      return [col1, col2, col3, col4, col5, col6, col7];
    },
    tableOption() {
      const d = {
        border: false,
        size: "medium",
      };
      return d;
    },
  },
  methods: {
    async onload(page, query = {}) {
      const pn = page.pageNum;
      const ps = page.pageSize;
      const q = this.query || {};
      const limit = 99999;
      const params = { ...query, ...q };
      try {
        const res = await getAuditLogsList(limit, params);
        const list = res.data;
        const total = Number(res.data.length);
        return { list, total };
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    getTableData() {
      this.isLoading = true;
      this.query = {
        //  entityName: this.searchName,
        startTime:
          this.searchDate && this.searchDate[0] ? this.searchDate[0] : "",
        endTime:
          this.searchDate && this.searchDate[1] ? this.searchDate[1] : "",
      };
      getAuditLogsList(99999, this.query)
        .then((res) => {
          this.tableData = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    searchData() {
      this.getTableData();
      this.$refs.listRef.apiSetPageNum();
    },
    resetData() {
      this.searchName = "";
      this.searchDate = [];
      this.query = {};
      this.$refs.listRef.apiSetPageNum();
      this.$refs.listRef.apiOnload();
    },
    //获取实体类型
    getEntityType() {
      queryEntityTypes().then((res) => {
        this.entityType = res;
      });
    },
    getRowEntityType(row = {}){
      return row?.entityId?.entityType
    }
  },
};
</script>
<style lang="scss" scoped>
.audit-logs {
  padding: 0 24px 24px 24px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .rm--bread {
    height: 44px;
    display: flex;
    align-items: center;
  }

  .com-top {
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    padding-bottom: 12px;
  }

  .com-bottom {
    height: calc(100% - 44px - 56px);
  }
}
</style>
