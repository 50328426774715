import http from "@/axios/index"
import { URI } from "@/config/axios"

// 获取审计日志数据
export const getAuditLogsList = (limit,params) => {
    return http({
        url: URI.system + 'api/audit/logs',
        method: 'get',
        params: {
            limit,
            ...params
        }
    })
}


//获取实体类型
export const queryEntityTypes = () => {
    return http({
        url:URI.system + 'api/entity/types',
        method:'get'
    })
}
